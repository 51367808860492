import { Link } from "react-router-dom";
import { Nav, Container, Row, Col } from "react-bootstrap";

import logo_facultad from "../assets/img/fi_color.png";

const Menu = () => {

  return (
    <>
    <header style={{width: "100%", zIndex: 10001 }} className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-1">
      <Container>
        <Row>
          <Col md={4}>
            <a href="https://ingenieria.utalca.cl/" rel="noreferrer" target="_blank"> 
              <img  width={150} src={logo_facultad} alt="logo_facultad"/>
            </a>
           
          </Col>
          <Col md={4}>
          <Link to="/"  style={{fontSize:20, color: "#000000" , textDecoration: "none"}}><h1>GUÍA DIDÁCTICA</h1></Link>
          </Col>
          <Col md={4}>

            <Nav>
             

            </Nav></Col>
        </Row>
      
      </Container>
    </header>
    <Row>
      <div style={{height:25, minHeight:25, backgroundColor:"rgb(54,86,140)"}}></div>
    </Row>
    </>
  );
};

export default Menu;
