import { Col, Container, Row } from "react-bootstrap";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube } from "react-icons/fa";

const Footer = () => {

	return (
		<>
			<footer className="text-capitalize" style={{ backgroundColor: process.env.REACT_APP_MAIN_GRAY }}>
				<Container>
					<Container>
						<Row >
							<Col md={3} sm={12} className="mvs-30 wow fadeIn" style={{ visibility: "visible", animationName: "fadeIn" , marginTop:20}}>
								<ul className=" list-block">
									<li>
										<a className="footer-li" rel="noreferrer"   href="http://transparencia.utalca.cl/">Transparencia Activa</a>
									</li>
									<li>
										<a className="footer-li" rel="noreferrer" href="https://www.utalca.cl/transparencia-pasiva/">Transparencia Pasiva</a>
									</li>
									<li>
										<a className="footer-li" rel="noreferrer" href="https://www.leylobby.gob.cl/instituciones/UN012">Ley del Lobby</a>
									</li>
								</ul>
							</Col>
							<Col md={3} sm={12} className="mvs-30 wow fadeIn" style={{ visibility: "visible", animationName: "fadeIn" , marginTop:20}}>
								<ul className=" list-block">
									<li>
										<a className="footer-li" rel="noreferrer" href="https://ingenieria.utalca.cl/EnVivo">En Vivo</a>
									</li>
									<li>
										<a className="footer-li" rel="noreferrer" href="https://ingenieria.utalca.cl/MemoriaFacultad25">Memoria 25 Años</a>
									</li>
									<li>
										<a className="footer-li" rel="noreferrer" href="https://ingenieria.utalca.cl/ManualMarca">Manual Marca</a>
									</li>
								</ul>
							</Col>
							<Col md={3} sm={12} className="mvs-30 wow fadeIn" style={{ visibility: "visible", animationName: "fadeIn" ,marginTop:20}}>
								<ul className=" list-block">
									<li>
										<a className="footer-li" href="https://ingenieria.utalca.cl/Home/Contacto">Contáctanos</a>
									</li>
									<li>
										<a className="footer-li" href="https://stats.uptimerobot.com/N0KGvTNM6r" rel="noreferrer">Estado Sitio</a>
									</li>
									<li>
										<a className="footer-li" href="https://ingenieria.utalca.cl/Home/About">Acerca de este sitio web</a>
									</li>
								</ul>
							</Col>
							<Col md={3} sm={12} className="mvs-30 wow fadeIn" style={{ visibility: "visible", textAlign:"left", animationName: "fadeIn" ,marginTop:20}}>
								<ul className=" list-block" style={{listStyle: "none" , textAlign:"left"}}>
									<li className="footer-li">
										Redes Sociales
									</li>
								</ul>
								<ul className="list-inline" style={{ marginTop:-15 , display:"flex",listStyle: "none" }}>
									<li><a rel="noreferrer" href="https://www.facebook.com/ingenieriautalcacurico" className="small rounded" title="Facebook"><FaFacebookF className="footer-socialIcon"/></a></li>
									<li><a rel="noreferrer" href="https://www.instagram.com/ingenieriautalca/" className="small rounded" title="Instagram"><FaInstagram className="footer-socialIcon"/></a></li>
									<li><a rel="noreferrer" href="https://twitter.com/ingUtalca" className="small rounded" title="Twitter"><FaTwitter className="footer-socialIcon"/></a></li>
									<li><a rel="noreferrer" href="https://cl.linkedin.com/in/utalca" className="small rounded" title="LinkedIn"><FaLinkedinIn className="footer-socialIcon"/></a></li>
									<li><a rel="noreferrer" href="https://www.youtube.com/user/canalUtalca" className="small rounded" title="Youtube"><FaYoutube className="footer-socialIcon"/></a></li>
								</ul>
								<ul className="list-inline" style={{ marginTop: -10,listStyle: "none"}}>
									<li>
									<img src="https://ingenieria.utalca.cl/Content/theme/img/acreditacion_cna.png" alt="Logo CNA" className="responsive" style={{ width: 150 }} />
									</li>
								</ul>
								
							</Col>
						</Row>
					</Container>
				</Container>
			</footer>
		</>
	);
};

export default Footer;
