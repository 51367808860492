import React from "react";
const Aprendizaje =  React.lazy(() => import("./pages/Aprendizaje"));
const Home =  React.lazy(() => import("./pages/Home"));
const Nivel =  React.lazy(() => import("./pages/Nivel"));
const Metodologia =  React.lazy(() => import("./pages/Metodologia"));
const Tecnica =  React.lazy(() => import("./pages/Tecnica"));
const NotFound =  React.lazy(() => import("./pages/NotFound"));


const routes = [
    { path : "/", component : Home },
    { path : "/aprendizaje/:id", component : Aprendizaje },
    { path : "/aprendizaje/nivel/:id/:nivel", component : Nivel },
    { path : "/aprendizaje/nivel/metodologia/:id/:nivel/:metodologia", component : Metodologia },
    { path : "/aprendizaje/nivel/metodologia/tecnica/:id/:nivel/:metodologia/:tecnica", component : Tecnica },
    { path : "*", component : NotFound },
    { path : "/404", component : NotFound }
]

export default routes;