import { Col, Container, Row } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";

const Preloader = () => {

    return (
        <>
            <Container >
                <Row style={{ alignItems: "center", justifyContent: "center", flex: 1, verticalAlign:"center" }}>
                    <Col md={2} sm={12} style={{verticalAlign:"center" }}>
                                               
                         <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color={process.env.REACT_APP_MAIN_COLOR_URL}
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        /> 
                         {/*<img src={loading} width="130%"  alt="loading" />*/}
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Preloader;