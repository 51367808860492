import { Container } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import uuid from 'react-uuid';
// Layout
import Layout from "./layout/Layout";
import React, { Suspense } from "react";
import routes from "./routes";

import Preloader from "./components/Preloader";
const App = (props) => {
  return (

    <Layout>
      <Container>
          <Suspense fallback={<Preloader />}>
            <Routes>
              {routes.map((route) => { return <Route path={route.path}  {...props} element={<route.component {...props} />} key={uuid()} exact /> })}
            </Routes>
          </Suspense>
      </Container>
    </Layout>

  );
};

export default App;
